.videoContainer {
  position: relative;
  &.isPaused {
    // Show play button overlay
    font-size: inherit;
    &:after {
      content: '';
      min-width: 73px;
      min-height: 73px;
      background: url('./play.svg') no-repeat 50% 50%;
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      margin-left: -1 * 72px / 2;
      margin-top: -1 * 72px / 2;
    }
  }
  &.isPlaying {
    font-size: inherit;
  }
}
.video {
  max-width: 100%;
  max-height: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  display: block;

  background: #000; // used to be #D1DBE3;
  box-shadow: 0 20px 40px 0 rgba(0,0,0,0.20);
}
video.video {
  cursor: pointer; // not for iframes
}
.ratio {
  width: 100%;
  position: relative;

  .video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &.ratio16x9 {
    padding-bottom: 9/16*100%;
  }
  &.ratio21x9 {
    padding-bottom: 9/21*100%;
  }
}
.fakePoster {
  display: none; // Hidden by default.
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* allow click-through for play/pause events */
  background-size: contain;
  background-position: center center;
  background-color: #000;
  background-repeat: no-repeat;
  z-index: 1;
}
.videoContainer.isPaused.isNearBeginning .fakePoster {
  display: block;
}
