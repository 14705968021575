@import '../App/variables';

.outer {
  @include ABC(33.3vw, 33.3vw);
}

.content.content {
  @media (min-width: $mobileBreakpoint) {

  }

}

.before, .after {
  position: relative;
  z-index: 1;
}

.before {
}

.after {
}

.searchBar {
  position: absolute;
  top: 0;
  right: 60px;
  z-index: 2;

  @media (min-width: $mobileBreakpoint) {
    top: 40px;
  }
  @media (min-width: $largerDesktopBreakpoint) {
    top: 90px;
  }
}

.posterContainer {

  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center; // or space-around
  align-content: center;
  position: relative;
  padding: 40px 60px 0 60px;
  box-sizing: border-box;
  // On small desktops there is a max width. Wrapping will occur anyway, so
  // might as well force it to look good.
  max-width: 1190px;
  margin: 0 auto;

  @media (min-width: $mobileBreakpoint) {
    padding-top: 40px;
  }
  @media (min-width: $largerDesktopBreakpoint) {
    padding-top: 130px;
  }

  .bgWrapper {
    background: no-repeat fixed 50% 50%;
    background-size: contain;

    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    // filter: blur(10px); // done with Canvas now

    &:after {
      content: '';
      background-size: 100% 100%;
      background: radial-gradient(49% 47% at center, rgba(0, 0, 0, 0.40) 50%, rgba(0, 0, 0, 0.70) 71%, #000000 94%) no-repeat fixed 0 0;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  @media (min-width: $mobileBreakpoint) {
    .bgWrapper {
      background-attachment: scroll;
      &:after {
        background-attachment: scroll;
      }
    }
  }

  @media (min-width: $largerDesktopBreakpoint) {
    margin: 0 -15px;
    max-width: none; // no wrapping of elements needed.
  }
}

.poster {
  background: #000 url('./category-img-1.jpg') no-repeat 50% 0;
  background-size: auto 306px;
  width: 240px;
  height: 460px;
  transition: height 0.5s ease-in-out,
    margin 0.5s ease-in-out,
    background-size 0.5s ease-in-out
  ;
  box-shadow: 0 2px 4px 0 #000000;
  border-radius: 10px;
  margin: 20px 15px 20px 15px;

  @media (min-width: $mobileBreakpoint) {
    height: 250px;
  }
  @media (min-width: $largerDesktopBreakpoint) {
    height: 460px;
  }

  &.clickable {
    cursor: pointer;
  }

  .posterFade {
    /* Sketch doesnt export pattern fills at this point */
    background: radial-gradient(50% 69% at center, rgba(0,0,0,0.00) 23%, #000000 120%) repeat-x 50% 0;
    border-radius: 10px 10px 0 0;
    background-size: auto 306px;
    height: 100%;
    transition: background-size 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 0;
    box-sizing: border-box;
  }

  .posterInner {
    font-size: 14px;
    color: #B6B6B6;
    text-align: center;
    line-height: 1.5;
    background-color: transparent;
    padding-bottom: 25px;

    @media (min-width: $mobileBreakpoint) {
      background-color: rgba(0,0,0,0.5);
    }
    @media (min-width: $largerDesktopBreakpoint) {
      background-color: transparent;
    }

    p {
      margin-top: 0;
      margin-bottom: 1em;
    }

    h3 {
      font-size: 24px;
      line-height: 1.3;
      font-weight: 900;
      margin-bottom: 0.25em;
    }
  }
}

.button {
  // Override default styling.
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 1em;
  min-width: 125px;
  border-width: 2px;
  margin: 0;
}

.poster:hover {

  height: 480px;
  margin-top: 0;
  background-size: auto 336px;

  .posterFade {
    background-size: auto 336px;
  }

  @media (min-width: $mobileBreakpoint) {
    height: 270px;
    background-size: auto 270px;

    .posterFade {
      background-size: auto 270px;
    }
  }

  @media (min-width: $largerDesktopBreakpoint) {
    height: 480px;
    background-size: auto 336px;

    .posterFade {
      background-size: auto 336px;
    }
  }

  .button {
    // Highlighted state colors, but only when parent is hovered.
    $borderColor: #EA5C1C;
    border-width: 2px;
    border-color: $borderColor;
    font-weight: 400;
    color: $borderColor;

    &:link,
    &:visited,
    &:active {
      color: $borderColor;
    }

    &:hover {
      border-color: lighten($borderColor, 10%);
      color: lighten($borderColor, 10%);
    }
  }
}
