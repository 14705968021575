.audioContainer {
  background: #222 no-repeat 50% 50%;
  background-size: cover;
  position: relative;
  display: inline-block;
  margin: 10px;

  &.small {
    width: 25px;
    height: 25px;
  }

  &.big {
    width: 200px;
  }

  &:before {
    content: '';
    padding-bottom: 100%;
    display: inline-block;
  }

  .volume {
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0);
    transition: 0.25s background-color ease-in-out;
    width: 100%;
    padding: 10px;
    cursor: default;
    display: flex;
    box-sizing: border-box;

    &.open {
      background: rgba(0, 0, 0, 0.75);
      input {
        display: inline-block;
      }
    }

    .volumeIcon {
      // position: absolute;
      // bottom: 10px;
      // left: 10px;
      flex: 0 0 auto;
      margin-right: 10px;
      cursor: pointer;
    }

    input {
      flex: 1 1 auto;
      display: none;
      margin: 0;
      width: calc(100% - 35px);
    }
  }

  .audioContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:after {
    }
  }

  &.isPlaying,
  &.isPaused {
    // Show button overlay
    &:after {
      content: '';
      background: no-repeat 50% 50%;
      background-size: 42px auto;
      min-width: 100px;
      min-height: 100px;
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1 * 100px / 2;
      margin-top: -1 * 100px / 2;
      cursor: pointer;
    }
  }
  &.isPaused {
    // Show play button overlay
    &:after {
      background-image: url('./play.svg');
      background-size: 47px;
    }
  }
  &.isPlaying {
    &:after {
      background-image: url('./stop.svg');
    }
  }

  &.audioContainer iframe {
    width: 1px;
    height: 1px;
    margin: 0;
    padding: 0;
    opacity: 0.01;
    overflow: hidden;
    pointer-events: none;
  }

  .timeProgress {
    position: absolute;
    top: 0;
    right: 0;
    color: #B6B6B6;
    font-size: 16px;
    background: rgba(0, 0, 0, 0.75);
    padding: 10px;
    border-bottom-left-radius: 15px;
    //text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
    .progress {
      color: #fff;
    }
    .duration {

    }
  }
}
