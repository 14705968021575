@import '../App/variables';


@media (min-width: $mobileBreakpoint) {
  .opener, .container {
    display: none;
  }
}

.opener {
  position: fixed;
  top: 22px;
  right: 17px;
  padding: 10px;
  cursor: pointer;
  z-index: 3;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #232323;
  padding: 0 30px 5px 30px;
  overflow: auto;
  box-sizing: border-box;

  &.open {
    display: block;
  }
  &.closed {
    display: none;
  }

  .submenu {
    margin-top: 15px;
    padding-left: 50px;
    margin-bottom: -20px;
    a {
      font-size: 20px;
      margin-bottom: 17px;
    }
  }

  .logo {
    width: 115px;
    margin-bottom: 30px;

    img {
      height: 102px;
      width: 115px;
    }
  }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 10px;
    cursor: pointer;
  }

  .language {
    position: absolute;
    right: 30px;
    top: 70px;
    font-size: 15px;

    ul {
      background-color: transparent;
      li {
        padding-top: 5px;
      }
    }
  }

  .child {
    margin-bottom: 40px;
    font-size: 25px;
    line-height: 1.2;
    font-family: $secondaryFont;
    font-weight: 400;
  }

  a {
    font-size: 25px;
    line-height: 1.2;
    display: block;

    &:link,
    &:visited,
    &:hover,
    &:active {
      color: #fff;
      text-decoration: none;
    }
    &:hover,
    &.active,
    &:global(.active) {
      font-weight: 900;
    }
  }
}
