@import '../App/variables';

.carousel {
  margin-top: 0;
  padding: 0 0 0 0;
  //padding: 0 0 50px 0;


  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: -60px;
  margin-right: -60px;


  @media (min-width: $mobileBreakpoint) {
    margin-top: 100px;
    padding: 0 0 50px 0;
  }

  .slide {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;


    flex: 1;
    position: relative;
    z-index: 1;
  }

  :global(.slide.selected) {
    z-index: 2;
    opacity: 1;
    transform: scale(1);
    filter: none;
    .slide .innerWidthBreaker {
      opacity: 1;
      pointer-events: auto;
    }
    .slideBlurredBg {
      display: none;
    }
  }

  .text {
    flex: 0 1 100%;
  }
  .frame {
    flex: 1 0 100%;
    padding: 0 0;

    @media (min-width: $mobileBreakpoint) {
      padding: 0 20px 0 20px;
    }

    iframe {
      width: 100%;
      height: 30vw;
      min-height: 300px;
      display: block;
    }
  }
  @media (min-width: 1024px) {
    .text {
      flex-basis: 40%;
    }
    .frame {
      flex-basis: 60%;
    }
    .frame {
      padding-left: 7vw;
    }
  }

  h1 {
    font-size: 50px;
    line-height: 1.1;
    margin: 0 0 0.1em 0;
    opacity: 0.2;
    color: #fff;
    word-wrap: anywhere;

    @media (min-width: $mobileBreakpoint) {
      font-size: 55px;
    }
  }

  :global {
    .carousel-slider {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    .slider-wrapper {
      margin-top: 0;
      margin-bottom: 0;
      flex: 1;
      display: flex;
      // There is only one element here, but we need flex-direction here
      // explicitly because Firefox can't overflow otherwise due to a bug.
      // https://onfe.uk/blog/p/the-woes-of-firefox-s-flexbox/
      flex-direction: column;
    }
    .slider {
      display: flex;
      flex: 1;
      height: auto;
    }
    .slide {
      background: transparent;
      height: auto;
      flex: 1;
      display: flex;
      flex-direction: column;
      text-align: left;

      opacity: 0.5;
      transform-origin: 50% 100%;
      transform: scale(0.9);
      // filter: blur(2px); //@todo re-enable this
      transition: transform 1s ease-in-out 0s, filter 1s ease-in-out 0s;
    }


    // Put the dot indicators under the slideshow
    .carousel-status.carousel-status {
      position: static;
      margin: 45px 0 20px 50px;
      display: block;
      font-size: 16px;
      line-height: 1.6;
      text-align: left;
      padding-left: 80px;
      box-sizing: border-box;
    }

    .control-dots.control-dots {
      margin: 0 0 0 50px;
      padding: 0;
      list-style-type: none;
      text-align: left;

      li {
        // margin: 0;
        // padding: 0;
      }
    }

    .slide.slide {
      background: transparent none;
      text-align: left;
      iframe {
        width: 100%;
        display: block;
        margin: 0;
        padding: 0;
      }
    }
  }

  .prev, .next {
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    padding: 10px;
    display: inline-block;
    position: absolute;
    bottom: 20px;
    left: -10px + 50px;
    z-index: 2;
    cursor: pointer;
    pointer-events: auto;

    &.enabled {}
    &.disabled {
      opacity: 0.2;
    }
  }

  .next.next {
    left: 30px + 50px;
  }

  .status {
    color: #B6B6B6;
    font-family: $secondaryFont;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.6;
    letter-spacing: 0.1em;

    strong {
      font-weight: 900;
    }
  }
}
