@import './variables.scss';

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}
body {
  @include fontReset();
  font-family: $primaryFont;
  background: #020000;
  color: #B6B6B6;
  font-size: 14px;
  line-height: 1.5;

  &.scrollableSiteEnabled {
    // For MacOS Safari: disable 'bounce' effect. Note: overflow-y is not sufficient.
    overflow: hidden;
    // Disable swipe-to-go-back on a lot of browsers. Note: Not MacOS Safari.
    overscroll-behavior-x: none;
    -webkit-overflow-scrolling: touch;
  }
}
#root {
  height: 100%;
}
a {
  color: #EA5C1C;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $secondaryFont;
}

h1 {
  font-size: 3.8em;
  font-weight: 900;
  letter-spacing: -1px;
  margin-top: 0.2em;
}

h2 {
  font-size: 2.4em;
  font-weight: 700;
  font-family: $primaryFont;
  line-height: 1.2;
  margin-bottom: 0.5em;
  letter-spacing: -0.045em;
}

p {
  // line-height: 1.4;
}

a {
}
