@import '../App/variables';

.container {
  display: flex;
  flex-wrap: nowrap;
  // overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  /*.before:first-child {
    display: none;
  }
  .after:last-child {
    display: none;
  }*/

  &.isNotScrollable {
    overflow: hidden;
  }
}

.initiallyHidden {
  max-height: 0 !important;
  min-height: 0 !important;
  height: 0 !important;
  overflow: hidden;
  pointer-events: none;
}

.fillScreen {
  width: 100%;
  height: 100%;
}

.offset {
  display: flex;
  flex-wrap: wrap;
  transition: 1s transform ease-in-out;

  @media (min-width: $mobileBreakpoint) {
    flex-wrap: nowrap;
  }
  @media (max-width: $mobileBreakpoint + 1) {
    transform: none !important;
  }
}

.content,
.before,
.after {
  flex-shrink: 0;
  height: 100%;
}

.outer {
  display: flex;
  flex: 0 0 100vw;
  min-height: 100vh;

  @media not screen and (min-width: $mobileBreakpoint) {
    &.outer {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.content {
  flex: 0 0 100vw; // take up 100% width
  position: relative; // allow child items to have position:absolute
  box-sizing: border-box; // allow padding on <Content>
  padding-bottom: 20px;
  overflow-x: hidden;
  overflow-y: auto; // Every slide can scroll if needed (which should be never).

  @media (min-width: $mobileBreakpoint) {
    padding-bottom: 90px; // About the size of the audio trackbar.
  }
}

:global {
  .scrollable-transition-appear,
  .scrollable-transition-appear-active,
  .scrollable-transition-appear-done,
  .scrollable-transition-enter,
  .scrollable-transition-enter-active,
  .scrollable-transition-enter-done {
  }

  .scrollable-transition-appear,
  .scrollable-transition-appear-active,
  .scrollable-transition-appear-done {

  }
  .scrollable-transition-enter,
  .scrollable-transition-enter-active,
  .scrollable-transition-enter-done {

  }

  .scrollable-transition-exit,
  .scrollable-transition-exit-active,
  .scrollable-transition-exit-done {
    // hide it.
  }
  .scrollable-transition-exit-done {
    max-height: 0;
    min-height: 0;
    overflow: hidden;
    pointer-events: none;
  }
}
