@mixin fontReset {
  font: {
    size: 1em;
    style: normal;
    variant: normal;
    weight: normal;
    stretch: normal;
  }
  line-height: 1.2;
  color: #fff;
}

// After, Before and Content blocks. Usage: @include ABC(10vw, 100px)
@mixin ABC($before, $after) {
  margin-left: $before;
  margin-right: $after;

  .before {
    width: $before;
    margin-left: -1 * $before;
  }
  .after {
    width: $after;
    margin-right: -1 * $after;
  }
}

$orange: #EA5C1C;

$primaryFont: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
$secondaryFont: 'Lato', 'Helvetica Neue', 'Helvetica', sans-serif;

$mobileBreakpoint: 1024px; // 640px;
$largerDesktopBreakpoint: 1440px;

