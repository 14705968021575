.grid {
  background: url('./grid.png') repeat 0 0;
  background-size: auto;

  // Position below the content of the parent container.
  position: absolute;
  z-index: 0;

  // Take up the entire size of the parent container.
  width: 100%;
  height: 100%;

  // Do not respond to click events, just like css backgrounds.
  pointer-events: none;
}

.bg {
  // Position below the content of the parent container.
  position: absolute;
  z-index: -1;

  // Take up the entire size of the parent container.
  width: 100%;
  height: 100%;

  // Do not respond to click events, just like css backgrounds.
  pointer-events: none;

  img {
    // Take up the entire picture container.
    width: 100%;
    height: 100%;
    // Keep aspect ratio.
    object-fit: cover;
    // To prevent a small gap after the image
    display: block;
  }
}

.container {
  position: relative;
}
