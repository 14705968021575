@import '../App/variables';

.container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0 80px;
  height: 95px;
  z-index: 2;
  display: none;

  @media (min-width: $mobileBreakpoint) {
    display: flex;
  }

  .child {
    flex: 0 0 18.2%;
    text-transform: capitalize;

    &.last {
      flex: 1 1 auto;
    }
  }

  .prev, .next {
    background: no-repeat 50% 50%;
    background-size: cover;
    position: absolute;
    top: -50px;
    left: 45px;
    width: 34px;
    height: 34px;
    display: block;

    &.disabled {
      display: none;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  .prev {
    background-image: url('./prev.svg');
  }
  .next {
    background-image: url('./next.svg');
    left: auto;
    right: 45px;
  }
  .hidden {
    display: none;
  }


  .indicator {
    position: absolute;
    bottom: 0;
    left: 60px;
    right: 80px;
    height: 70px;
    width: auto;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;

    img, svg, canvas {
      width: 100%;
      height: auto;
    }
  }

  .audioTrackBar {
    svg {
      display: block;
    }
  }

  .active a,
  a:active {
    font-weight: bold;
  }

  a {
    font-size: 14px;
    line-height: 2;

    &:link,
    &:visited,
    &:hover,
    &:active {
      color: #fff;
      text-decoration: none;
    }
  }
}

.simpleAudioTrackbar {
  position: relative;
  width: 100%;
  padding-bottom: 5.434782609%;

  img {
    width: 100%;
    height: auto;
    position: absolute;
    transition: clip-path 1s 0s linear;
  }
}
