@import '../App/variables';

.languageToggle {
  position: absolute;
  top: 40px;
  right: 40px;
  text-align: right;

  &.active .currentLanguage:before {
    transform: rotate(180deg);
  }

  .child {
    background: rgba(0, 0, 0, 0.2);
    display: none;
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: block;
      margin: 0;
      padding: 0 0 0 15px;
      cursor: pointer;

      &:hover {
        color: $orange;
      }

      &.active {
        display: none;
      }
    }
  }

  &.active .child {
    display: block;
  }
}
.currentLanguage {
  color: $orange;
  cursor: pointer;

  &:before {
    content: '';
    background: url('./chevron_down.svg') no-repeat 0 50%;
    background-size: 10px auto;
    margin-right: 5px;
    transition: 0.2s transform ease-in-out;
    height: 10px;
    width: 10px;
    display: inline-block;
  }
}
