@import '../App/variables.scss';

.case {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  // This element may not have a z-index, because that breaks the overlay effect on the title.
  position: relative;

  .primaryTitle {
    h1, h2 {
      opacity: 0.2;
      font-size: 4.1em;
      line-height: 1.1;
      margin-bottom: 0;


      @media (min-width: $mobileBreakpoint) {
        font-size: 7.1em;
      }
    }
  }


  .forcedBreak {
    flex: 0 0 100%;
    height: 0;
  }

  .primaryTitle,
  .primaryPage,
  .primaryEmbed,
  .content,
  .teamMembers,
  .embeds,
  .primaryEmbed,
  .teamMembers,
  .embeds {
    flex: 1 1 100%;
    margin-bottom: 50px;
  }

  @media (min-width: $mobileBreakpoint) {
    .primaryTitle,
    .primaryPage,
    .primaryEmbed,
    .content,
    .teamMembers,
    .embeds {
      flex: 0 1 50%;
      margin-bottom: 100px;
    }

    .primaryTitle {
      flex: 1 1 50%;
      margin-bottom: 50px;
    }

    .primaryEmbed,
    .teamMembers,
    .embeds:nth-child(2n) {
      flex-basis: 41.6%;
    }
  }

  .primaryEmbed {

  }
  .primaryEmbed {
    &:after {
      content: '';
      flex-basis: 100%;
      display: block;
      height: 0;
    }
  }
  .videoEmbed {
    display: flex;
    flex-direction: column-reverse;

    h2 {
      order: 999;
    }

    h3 {
      font-family: $primaryFont;
      font-weight: 700;
      margin-top: 0.5em;
      font-size: 14px;
      text-align: right;
    }

    > div {
      margin-bottom: 10px;
      margin-right: 10px;
    }
  }
  .imageEmbed {
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .embeds.noAudioEmbeds {
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -10px;
    h2 {
      flex-basis: 100%;
    }
    > div {
      flex-basis: calc(50% - 20px);
      margin: 0 10px;
    }
  }

  .teamMembers {
    a {
      display: inline-block;
    }
  }
  .teamMember {
    background: none no-repeat 50% 50%;
    background-size: cover;
    width: 20%;
    max-width: 100px;
    position: relative;
    overflow: hidden;
    display: inline-block;

    &:hover {
      opacity: 0.8;
    }

    &:after {
      content: '';
      width: 100%;
      padding-bottom: (16/9) * 100%;
      display: block;
    }

    span {
      position: absolute;
      visibility: hidden;
    }
  }
}
