.bg {
  // Position below the content of the parent container.
  position: absolute;
  z-index: -1;

  // Take up the entire size of the parent container.
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.5s opacity linear;

  // Do not respond to click events, just like css backgrounds.
  pointer-events: none;

  video {
    // Take up the entire picture container.
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // Keep aspect ratio.
    object-fit: cover;
    // To prevent a small gap after the image
    display: block;
    opacity: 0;
  }
  video.current {
    opacity: 1;
  }
  video.next {
  }
}
.bg.loaded {
  background: #000;
  opacity: 1;
}
