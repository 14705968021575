@import '../App/variables';

.outer {
  @include ABC(0, 0);
}

.pinPoint {
  background-color: #fff;
  position: absolute;
  padding: 25px 35px;
  top: 48%;
  left: 51%;
  z-index: 1;
  transform: translate(-54%, -48%);
  color: $orange;
  text-decoration: none;
  margin-top: -30px;
  &:hover {
    background: rgba(255,255,255,0.95);

    &:after {
      border-top-color: rgba(255,255,255,0.95);
    }
  }
  span {
    color: #000;
    font-weight: bold;
    display: block;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;

    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
  }
  &:before {
    content: '';
    background: url('./icons/placemark.svg') no-repeat 50% 50%;
    background-size: 100% auto;
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    height: 30px;
  }
}

.bg {
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0.04) 25%, black 79%);
  background-image: url("background-compressed.jpg");
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top:0;
  right: 0;
  z-index: -1;
}

.Contact {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  position: relative;
  font-size: 14px;
  padding: 0px 80px;
  box-shadow: inset 0 -40px 100px rgba(0, 0, 0, 0.85);

  h1 {
    font-size: 54px;
    margin: 0;
    font-weight: bold;
  }
}

.contactWrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  .small {
    width: 30%;
  }
}

.contactDetail {
  display: inline-block;
  padding: 25px 60px 25px 25px;
  border: solid 1px #212121; // was #B6B6B6;
  width: auto;
  margin-top: 10px;
  margin-bottom: 15px;

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
    margin-bottom: 6px;
    font-size: 14px;
  }

  a {
    text-decoration: none;
  }
}

.links {
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  li {
    margin-bottom: 7px;
  }
  a {
    color: #fff;
  }
}

.placeName {
  position: absolute;
  right: calc(30px + 80px);
  bottom: 20px;
  opacity: 0.33;
}

.Socials {
  position: static;
  bottom: 15px;
  right: 80px;
  margin: 0;
  text-align: center;

  @media (min-width: $mobileBreakpoint) {
    position: absolute;
    text-align: left;
  }

  ul {
    list-style-type: none;
  }
  li {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin-left: 20px;
    a {
      cursor: pointer;
      svg path {
        transition: ease-in-out 0.4s;
      }
      &:hover {
        svg path {
          fill: $orange;
        }
      }
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .bg {
    width: 55%;
  }
  .pinPoint {
    left: 74%;
  }

  .placeName {
    h2 {
      font-size: 94px !important;
    }
  }

  .contactWrap .small {
    width: 50%;
  }
}

@media only screen and (max-width: 968px) {
  .placeName {
    position: relative;
    right: unset;
    opacity: 0.1;
  }

  .Socials {
    position: relative;
    right: unset;
    padding:0;
    li {
      margin-left: 0;
      margin-right: 20px;
    }
  }

  .bg {
    width: 50%;
  }
  .pinPoint {
    left: 76%;
  }
}

@media only screen and (max-width: 768px) {
  .bg {
    width: 100%;
    height: 54%;
    bottom: 0;
    top: unset;
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.04) 34%, black 79%);
    background-size: 230%;
    background-position: 33% 30%;
  }
  .pinPoint {
    display: none;
  }

  .placeName {
    display: none;
  }

  .Contact {
    padding: 70px 20px;
  }

  .contactWrap {
    position: relative;
    transform: translate(0);
    top: unset;
    .small {
      width: calc(100% - 20px);
    }
  }
}
