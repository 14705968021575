@import '../App/variables';

.searchBar {
  display: inline;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  input {
    background: url('./search.svg') no-repeat 25px 50%;
    background-size: 16px auto;
    padding: 5px 20px 5px 50px;
    min-width: 125px;
    color: #fff;
    text-transform: none;

    border: 1px solid rgba(255,255,255,0.5);
    border-radius: 40px;
    line-height: 2;
    outline: 0;
    margin-bottom: 15px;
    cursor: pointer;
    font-family: $primaryFont;
    font-size: 13px;
    transition: min-width 0.5s ease-in-out;

    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }

    &:focus {
      min-width: 240px;
    }
  }
}

.visuallyHidden {
  overflow: hidden;
  height: 0;
  width: 0;
  display: inline-block;
}
