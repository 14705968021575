@import '../App/variables.scss';

.searchBar {
  text-align: right;
  width: 100%;
  margin-top: -20px;
}

.contentCategory {
  > h3 {
    font-size: 34px;
    font-weight: 900;
    color: #fff;
    padding-bottom: 0.25em;
    border-bottom: 1px solid #B6B6B6;
    margin-bottom: 0.5em;
  }
}

.grid {
  perspective: 1000px;

  $width: 30vw;
  $perRow: 5;
  $gutter: 10px;
  overflow: hidden;
  margin: 0 -1 * $gutter;

  .gridItemFrame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    padding: 0;
    margin: 0;
    border: 0;
  }

  .gridItem {
    background: no-repeat 50% 50%;
    background-size: cover;
    position: relative;
    float: left;
    margin: $gutter;
    border-radius: 6px;
    opacity: 0.7;
    transition-duration: 0.3s;
    width: calc(33.33% - #{$gutter * 2});
    height: 17vw;

    @media (min-width: $mobileBreakpoint) {
      // width: calc(#{$width} - (#{$gutter} * 2));
      // height: calc((#{$width} - (#{$gutter} * 2)) * 9 / 16);
    }

    &:before {
      content: '';
      background: url('./clickIcon.svg') no-repeat 0 0;
      background-size: 100% auto;
      width: 30px;
      height: 30px;
      position: absolute;
      // right: 50%;
      // margin-left: -20px / 2;
      right: 20px;
      bottom: 20px;
      display: none;
    }

    &.playing .gridItemInner:after {
      background-image: url('../Audio/stop.svg');
    }

    .gridItemTitleAndControl {
      background: linear-gradient(180deg, rgba(0, 0, 0, .85), transparent 30%) no-repeat 0 0;
      background-size: 100% 100px;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 5px 0 10px;
      z-index: 1;
      width: 100%;
      box-sizing: border-box;

      h3, a {
        position: static;
      }
      h3 {
        width: 100%;
        margin-bottom: 3px;
      }

      @media (min-width: $mobileBreakpoint) {
        padding: 10px 20px 0 20px;

        h3 {
          margin-bottom: 0.5em;
        }
      }
    }

    .gridItemInner {
      &:after {
        display: none; // Disabled for now

        content: '';
        background: url('../ShowreelPage/play.svg') no-repeat 50% 50%;
        width: 73px;
        height: 73px;
        margin-left: -1 * 72px / 2;
        margin-top: -1 * 72px / 2;
        left: 50%;
        top: 50%;
        position: absolute;
        z-index: 2;
      }
      width: 100%;
      height: 100%;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.5s ease-in-out;
      position: relative;
    }

    &.activeGridItem {
      opacity: 1;

      &:before {
        display: none;
      }

      .gridItemInner {
        opacity: 1;
        cursor: default;
        pointer-events: auto;
      }
    }

    &:hover
    {
      cursor: pointer;
      opacity: 1;
    }

    img
    {
      position: absolute;
      top: 100px;
      left: 180px;
      background-color: rgba(0,0,0,0.4);
      border-radius: 50%;
      box-shadow: 0 0 40px rgba(0,0,0,0.4);
    }

    h3
    {
      width: calc(100% - 200px);
      position: absolute;
      left: 20px;
      bottom: 20px;
      font-weight: normal;
      text-shadow: 2px 2px 2px rgba(0,0,0,0.4);
      color: #fff;
      margin: 0;
    }
  }
}

.button {
  min-height: 2em;
  min-width: 150px;
  box-sizing: border-box;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 1.5;
  position: absolute;
  margin-bottom: 0;
  bottom: 20px;
  right: 20px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);

  border: 1px solid #EA5C1C;
  background: #EA5C1C;
  border-radius: 30px;
  color: #fff;

  &:link, &:visited, &:hover, &:active {
    color: #fff;
  }
  &:hover {
    background-color: lighten(#EA5C1C, 5%);
    border-color: lighten(#EA5C1C, 5%);
    color: #fff;
  }
}
