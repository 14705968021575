.Tagline {
  // This element can overflow due to
  // the text having a small line height.
  overflow: hidden;
  display: flex;
  flex: {
    grow: 1;
    shrink: 0;
    direction: column;
  }
  justify-content: flex-end;

  // Visually small text above the tag line.
  h1 {
    font: {
      size: 14px;
      weight: 700;
    }
    margin: 0;
    padding: 0;
    opacity: 0.3;
  }
  // The tag line itself.
  // It's displayed twice on the page for a special visual effect.
  h2 {
    font: {
      size: 127px;
      weight: 900;
    }
    text-transform: uppercase;
    line-height: 0.95;
    letter-spacing: -0.01em;

    margin: 0;
    padding: 0;
    opacity: 0.2;
  }
  // Contains the two titles, original and clone.
  // This container is needed so that the two clones can
  // be positioned on top of each other.
  .Container {
    position: relative;
    // It may not have a z-index, because the clone
    // contains a blend mode.
    z-index: auto;
  }
  .Clone {
    mix-blend-mode: overlay;
    opacity: 1;
    // Due to mix-blend-mode, it may not be positioned
    // relative or absolutely.
    position: static;
    pointer-events: none;
  }
  h2 {
    // Position on top of the clone.
    position: absolute;
    top: 0;
    left: 0;
  }
}

