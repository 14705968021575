@import '../App/variables';

.container {
  @import './LightboxPageVariables.scss';

  background: #191818 url('./bg-default.jpg') no-repeat 50% 50%;
  background-size: cover;
  min-height: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 5;

  @media (min-width: $mobileBreakpoint) {
    padding: 0 $lightboxPagePadding 0 $lightboxPagePadding;
  }

  .header {
    height: $lightboxPageTopPadding;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;

    @media (min-width: $mobileBreakpoint) {
      margin-left: 0;
    }

    div:first-child {
      width: 150px;
    }

    div + div {
      flex: 1 1 auto;
      text-align: right;
    }
  }

  .logo {
  }

  .back {
    display: inline-block;
    margin-top: -$lightboxPageTopPadding;
    line-height: 2;
    vertical-align: center;
    text-decoration: none;
    transition: color 0.25s ease-in-out;
    opacity: 1;
    padding-right: 10px;

    @media (min-width: $mobileBreakpoint) {
      padding-right: 0;
    }

    &:link,
    &:visited,
    &:hover,
    &:active {
      color: #B6B6B6;
      text-decoration: none;
    }

    &:hover {
      color: #ffffff;
    }

    img {
      margin-right: 0.5em;
    }
  }

  color: #B6B6B6;
}
.content {
  flex: 1; // take up all vertical space
  display: flex;
  flex-direction: column;
}

:global {
  // Show element on top of the rest
  .lightbox-transition-appear,
  .lightbox-transition-appear-active,
  .lightbox-transition-appear-done {
  }

  .lightbox-transition-enter,
  .lightbox-transition-enter-active,
  .lightbox-transition-enter-done,
  .lightbox-transition-exit,
  .lightbox-transition-exit-active,
  .lightbox-transition-exit-done {
    transition: 1s opacity ease-in-out;
  }

  .lightbox-transition-enter,
  .lightbox-transition-enter-active,
  .lightbox-transition-exit,
  .lightbox-transition-exit-active {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    opacity: 0;
  }

  .lightbox-transition-enter-active,
  .lightbox-transition-enter-done {
    opacity: 1;
  }

  .lightbox-transition-exit {
    opacity: 1;
  }
  .lightbox-transition-exit-active,
  .lightbox-transition-exit-done {
    opacity: 0;
  }
}
