@import '../App/variables';

.outer {
  @include ABC(100px, 200px);
}

.content.content {
  min-height: 100%;
  padding: 20px 0 20px 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  align-content: center;

  @media (min-width: $mobileBreakpoint) {
    padding: 20px 80px 100px 80px;
  }

  .videoContainer.video16x9 {
    width: 100%;
    padding-bottom: 9/16*100%;
    position: relative;

    .video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .videoContainer {
    position: relative;

    @media (min-width: $mobileBreakpoint) {
      margin-left: 0;
      margin-right: 0;
    }

    &.isPaused {
      // Show play button overlay
      font-size: inherit;
      &:after {
        content: '';
        min-width: 73px;
        min-height: 73px;
        background: url('./play.svg') no-repeat 50% 50%;
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1 * 72px / 2;
        margin-top: -1 * 72px / 2;
      }
    }
    &.isPlaying {
      font-size: inherit;
    }
  }
  .video {
    max-width: 100%;
    max-height: 100%;

    background: #000; // was #D1DBE3;
    box-shadow: 0 20px 40px 0 rgba(0,0,0,0.20);
    cursor: pointer;
  }
}

.contentFade {
  width: 100%;

  @media (min-width: $mobileBreakpoint) {
    max-width: 860px;
  }
}
:global {
  .showreelpage-content {
    &-enter {
      opacity: 0;
    }
    &-enter-active {
      opacity: 1;
      transition: 0.5s opacity ease-in-out 2.5s;
    }
    &-enter-done {}

    &-exit {
      opacity: 1;
    }
    &-exit-active {
      opacity: 0;
      transition: .5s opacity ease-in-out 0s;
    }
    &-exit-done {}
  }
}


.after {
  position: relative;
  overflow: visible;

  .animationLtr div,
  .animationRtl div {
    pointer-events: none;
    opacity: 0.14;
    font-size: 40px;
    font-family: $secondaryFont;
    font-weight: 900;
    color: #fff;
    letter-spacing: 0.55px;
    line-height: 40px;
    white-space: nowrap;
    text-transform: uppercase;
    z-index: -1;
    margin-left: -100vw;
    position: absolute;

    @media (min-width: $mobileBreakpoint) {
      font-size: 14vh;
      line-height: 13vh;
    }
  }

  .bg-large.bg-large {
    background: url('./bg.jpg') no-repeat 50% 50%;
    background-size: cover;
    opacity: 1;
    z-index: -2;
    left: 0;
    width: 100vw;
    height: 100%;
  }


  $offsetA: 6vw;
  $offsetB: 24vw;
  $offsetC: 36vw;
  $offsetD: 17vw;

  .a {
    top: 20%;
    left: $offsetA;
  }
  .b {
    top: 34%;
    left: $offsetB;
  }
  .c {
    top: 50%;
    left: $offsetC;
  }
  .d {
    top: 66%;
    left: $offsetD;
  }

  &:global(.showreelpage-bg-enter) .animationLtr {
    // Animate from the left side of the screen (-$offsetX) minus own width (-100%).
    .a, .b, .c, .d {
      opacity: 0;
    }
    .a {
      transform: translateX(calc(-100% - #{$offsetA}));
    }
    .b {
      transform: translateX(calc(-100% - #{$offsetB}));
    }
    .c {
      transform: translateX(calc(-100% - #{$offsetC}));
    }
    .d {
      transform: translateX(calc(-100% - #{$offsetD}));
    }
    .bg-large {
      transform: translateX(-25vw);
    }
  }
  &:global(.showreelpage-bg-enter-active) .animationLtr {
    // Animate to position defined by 'left' by zero'ing translation.
    .a, .b, .c, .d {
      transform: translateX(0);
      opacity: 0.14;
      transition: 1s transform ease-out 0.75s, opacity 0.25s ease-in-out 0.75s;
    }
    .bg-large {
      transform: translateX(0);
      transition: 2s transform ease-out 0s;
    }
  }
  &:global(.showreelpage-bg-enter-done) .animationLtr {
  }
  &:global(.showreelpage-bg-exit) .animationLtr {
    // Animate from current position.
    .a, .b, .c, .d {
      transform: translateX(0);
      opacity: 0.14;
    }
    .bg-large {
      transform: translateX(0);
    }
  }
  &:global(.showreelpage-bg-exit-active) .animationLtr {
    // Animate to right side of the screen.
    .a, .b, .c, .d {
      transform: translateX(calc(100vw));
      opacity: 0;
      transition: 1s transform ease-in .5s, opacity 0.25s ease-in-out 1s;
    }
    .bg-large {
      transform: translateX(-25vw);
      transition: 1.5s transform ease-in 0s;
    }
  }

  &:global(.showreelpage-bg-enter) .animationRtl {
    // Animate from right side of the screen.
    .a, .b, .c, .d {
      transform: translateX(100vw);
      opacity: 0;
    }
    .bg-large {
      transform: translateX(25vw);
    }
  }
  &:global(.showreelpage-bg-enter-active) .animationRtl {
    // Animate to position defined by 'left' by zero'ing translation.
    .a, .b, .c, .d {
      transform: translateX(0);
      opacity: 0.14;
      transition: 1s transform ease-out 0.5s, opacity 0.25s ease-in-out .5s;
    }
    .bg-large {
      transform: translateX(0);
      transition: 2s transform ease-out 0s;
    }
  }
  &:global(.showreelpage-bg-enter-done) .animationRtl {
  }
  &:global(.showreelpage-bg-exit) .animationRtl {
    // Animate from current position
    .a, .b, .c, .d {
      transform: translateX(0);
      opacity: 0.14;
    }
    .bg-large {
      transform: translateX(0);
    }
  }
  &:global(.showreelpage-bg-exit-active) .animationRtl {
    // Animate to left side of the screen.
    .a, .b, .c, .d {
      opacity: 0;
      transition: 1.5s transform ease-in 0s, opacity 0.25s ease-in-out 0.25s;
    }
    .a {
      transform: translateX(calc(-100% - #{$offsetA}));
    }
    .b {
      transform: translateX(calc(-100% - #{$offsetB}));
    }
    .c {
      transform: translateX(calc(-100% - #{$offsetC}));
    }
    .d {
      transform: translateX(calc(-100% - #{$offsetD}));
    }
    .bg-large {
      transform: translateX(25vw);
      transition: 1.5s transform ease-in 0s;
    }
  }
}
