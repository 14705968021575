@import '../App/variables';

.outer {
  @include ABC(200px, 400px);
}

.content.content {
  min-height: 100%;
  padding: 20px 40px 20px 40px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  align-content: center;
  box-sizing: border-box;

  @media (min-width: $mobileBreakpoint) {
    padding: 20px 80px 100px 80px;
  }

  .primary, .secondary {
    box-sizing: border-box;
  }
  .videoContainer.video16x9 {
    width: 100%;
    padding-bottom: 9/21*100%;
    position: relative;

    .video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
  .videoContainer {
    position: relative;
    &.isPaused {
      // Show play button overlay
      font-size: inherit;
      &:after {
        content: '';
        min-width: 73px;
        min-height: 73px;
        background: url('../ShowreelPage/play.svg') no-repeat 50% 50%;
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1 * 72px / 2;
        margin-top: -1 * 72px / 2;
      }
    }
    &.isPlaying {
      font-size: inherit;
    }
  }
  .video {
    max-width: 100%;
    max-height: 100%;

    background: #000; // used to be #D1DBE3;
    box-shadow: 0 20px 40px 0 rgba(0,0,0,0.20);
    cursor: pointer;
  }

  .animationRtl {}
  .animationLtr {}
}


.primary {
  flex: 1 0 100%;
}

.secondary {
  flex: 1 1 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    max-width: 100%;
  }

  margin-left: -40px;
  margin-right: -40px;
  @media (min-width: $mobileBreakpoint) {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (min-width: 1024px) {
  .content.content {
    flex-direction: row;
  }

  .secondary {
    padding-right: 80px;
    flex: 1 1 66%;

    img {
      margin-top: 60px;
    }
  }
  .primary {
    flex: 1 0 33%;
  }
}


.buttonList {
  margin-top: 3em;
  width: 100%;

  .button {
    width: 100%;
  }
}


/*
  The 'after' element contains background elements that are animated.
*/
.after {
  position: relative;
  overflow: visible;

  .bg-large, .bg-lower, .bg-upper {
    margin-left: -100vw; // width of Content element.
    position: absolute;
    pointer-events: none;
    z-index: -1;
    opacity: 0.2;
    box-shadow: 0 0 55px 20px #000;
  }

  .bg-large {
    opacity: 1;
    box-shadow: none;
    left: 0;
    top: 0;
    background: url('./bg.jpg') no-repeat 50% 50%;
    background-size: cover;
    width: 100vw;
    height: 100%;
    overflow: hidden;
  }

  .bg-lower {
    left: -8.5vw;
    top: 45.5%;
    transform: translate3d(0, 0, 0);
  }
  .bg-upper {
    left: 50vw;
    top: 6%;
    transform: translate3d(0, 0, 0);
  }

  /*
  // @todo Find out why this won't work: parent with classes that change
  &.animationLtr,
  &.animationRtl {
    &:global(.about-page-bg-enter) {
      outline: 2px solid yellow;
      // Start from these values when entering
      .bg-upper {
        outline: 5px solid red;
        left: 1vw;
        opacity: 0;
      }
      .bg-lower {
        outline: 5px solid red;
        left: -500px;
        opacity: 0;
      }
    }
    &:global(.about-page-bg-exit) {
      // Start from these values when leaving
      .bg-upper {
        outline: 5px solid orange;
        left: 50vw;
        opacity: 0.2;
      }
      .bg-lower {

        outline: 5px solid orange;
        left: 0.5vw;
        opacity: 0.2;
      }
    }
  }

  &.animationRtl {
    &:global(.about-page-bg-enter) {
      // Start from these values when entering from the right
      .bg-upper {
        left: 100vw;
      }
      .bg-lower {
        left: 50vw;
      }
    }
  }

  &.animationLtr,
  &.animationRtl {
    &:global(.about-page-bg-enter-active) {
      // While entering the page: To this state
      .bg-upper {
        left: 50vw;
        opacity: 0.2;
        transition: left 1s ease-out 500ms, opacity 0.25s ease-in-out 500ms;
      }
      .bg-lower {
        left: -8.5vw;
        opacity: 0.2;
        transition: left 1s ease-out 500ms, opacity 0.25s ease-in-out 500ms;
      }
    }
    &:global(.about-page-bg-enter-done) {
      // When animation is done
      .bg-lower {

        left: -8.5vw;
        opacity: 0.2;
      }
      .bg-upper {

        left: 50vw;
        opacity: 0.2;
      }
    }
    &:global(.about-page-bg-exit-active) {
      // While leaving the page: To this state
      .bg-upper {
        left: 100vw;
        opacity: 0;
        transition: left 1s ease-out, opacity 0.25s ease-in-out 0.75s;
      }
      .bg-lower {
        left: 50vw;
        opacity: 0;
        transition: left 1s ease-out, opacity 0.25s ease-in-out 0.75s;
      }
    }
    &:global(.about-page-bg-exit-done) {
      // When animation is done
    }
  }

  &.animationRtl {
    &:global(.about-page-bg-exit-active) {
      // While leaving the page from right-to-left: To this state
      .bg-upper {
        left: 1vw;
      }
      .bg-lower {
        left: -500px;
      }
    }
  }
  */


  // This className is doubled so it's just as 'specific' as the next block.
  &.animationLtr,
  &.animationRtl {
    :global {
      .about-page-bg-lower-enter {
        // When entering the page: From this state:
        left: -500px;
        opacity: 0;
      }
      .about-page-bg-lower-exit {
        // When leaving the page: From this state
        left: -8.5vw;
        opacity: 0.2;
      }

      // --- Animation #2 ---

      .about-page-bg-upper-enter {
        // When entering the page: From this state
        left: 1vw;
        opacity: 0;
      }
      .about-page-bg-upper-exit {
        // When leaving the page: From this state
        left: 50vw;
        opacity: 0.2;
      }

      // --- Animation #3 ---

      .about-page-bg-large-enter {
        // When entering the page: From this state
        left: -25vw;
      }
      .about-page-bg-large-exit {
        // When leaving the page: From this state
        left: 0;
      }
    }
  }

  &.animationRtl {
    :global {
      .about-page-bg-lower-enter {
        // When entering the page from right-to-left: From this state
        left: 50vw;
      }

      // --- Animation #2 ---

      .about-page-bg-upper-enter {
        // When entering the page from right-to-left: From this state
        left: 100vw;
      }

      // --- Animation #3 ---

      .about-page-bg-large-enter {
        // When entering the page from right-to-left: From this state
        left: 25vw;
      }
    }
  }

  &.animationLtr,
  &.animationRtl {
    :global {
      .about-page-bg-lower-enter-active {
        // When entering the page: To this state
        left: -8.5vw;
        opacity: 0.2;
        transition: left 1s ease-out 500ms, opacity 0.25s ease-in-out 500ms;
      }
      .about-page-bg-lower-enter-done {
        // After animation is done
      }
      .about-page-bg-lower-exit-active {
        // When leaving the page: To this state
        //left: 50vw;
        opacity: 0;
        //transition: left 1s ease-out, opacity 0.25s ease-in-out 0.75s;
        transition: left 1s ease-out, opacity 0.5s ease-in-out 0s;
      }
      .about-page-bg-lower-exit-done {
        // After animation is done
      }

      // --- Animation #2 ---

      .about-page-bg-upper-enter-active {
        left: 50vw;
        opacity: 0.2;
        transition: left 1s ease-out 500ms, opacity 0.25s ease-in-out 500ms;
      }
      .about-page-bg-upper-enter-done {
        // After animation is done
      }
      .about-page-bg-upper-exit-active {
        // When leaving the page: To this state
        //left: 1vw;
        //left: 100vw;
        opacity: 0;
        //transition: left 1s ease-out, opacity 0.25s ease-in-out 0.75s;
        transition: left 1s ease-out, opacity 0.5s ease-in-out 0s;
      }
      .about-page-bg-upper-exit-done {
        // After animation is done
      }

      // --- Animation #3 ---

      .about-page-bg-large-enter-active {
        left: 0;
        transition: left 1s ease-out 500ms, opacity 0.25s ease-in-out 500ms;
      }
      .about-page-bg-large-enter-done {
        // After animation is done
      }
      .about-page-bg-large-exit-active {
        // When leaving the page: To this state
        transition: left 1s ease-out, opacity 0.5s ease-in-out 0s;
      }
      .about-page-bg-upper-exit-done {
        // After animation is done
      }
    }
  }

  &.animationRtl {
    :global {
      .about-page-bg-lower-exit-active {
        // When leaving the page from right-to-left: To this state
        left: -500px;
      }

      // --- Animation #2 ---
      .about-page-bg-upper-exit-active {
        // When leaving the page from right-to-left: To this state
        left: 1vw;
      }

      // --- Animation #3 ---
      .about-page-bg-large-exit-active {
        // When leaving the page from right-to-left: To this state
        left: 5vw;
      }
    }
  }

}
