.Container {
  position: relative;
  overflow: hidden;
}

.Original {
  // Position below the clone.
  position: absolute;
  top: 0;
  left: 0;
  // This should stack it behind the other text...
  z-index: 0;
}
.Clone {
  mix-blend-mode: overlay;
  opacity: 1 !important;
  // Due to mix-blend-mode, it may not be positioned
  // relative or absolutely.
  position: static;
  pointer-events: none;
  // This seems to help
  transform: translate3d(0, 0, 0);
}
