@import '../App/variables';

.outer {
  @include ABC(33.3vw, 33.3vw);
}

.content.content {
  height: 80vh;
  // This element is an exception to the rest: it must overflow (for the fall-off gradient)
  overflow: visible;

  @media (min-width: $mobileBreakpoint) {
    height: auto;
    min-height: 100%;

    .mobile {
      display: none;
    }
  }

  .mobile {
    padding: 10px 30px 0 30px;
  }

  .status {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    color: $orange;
  }

  .mobile.mobileFilterVisible ~ .filterList {
    display: block;
  }

  .filterList {
    position: absolute;
    z-index: 2;

    top: 200px;
    left: 10px;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 20px;
    padding: 10px 20px;
    display: none;

    input {
      display: none;
    }

    @media (min-width: $mobileBreakpoint) {
      left: 90px;
      bottom: 200px;
      top: auto;
      background: transparent;
      border-radius: 0;
      padding: 0;
      display: block;

      input {
        display: inline;
      }
    }
  }

  .filterList,
  .mobile {
    input,
    button {
      border: 1px solid rgba(255,255,255,0.5);
      background: rgba(15,15,15, 0.7);
      border-radius: 40px;
      line-height: 2;
      padding: 10px 30px;
      text-transform: uppercase;
      outline: 0;
      color: #fff;
      margin-bottom: 15px;
      cursor: pointer;
      font-family: $primaryFont;
      font-size: 13px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.7);
      }

      &.active {
        background-color: #EA5C1C;
        border-color: #EA5C1C;

        &:hover {
          // background-color: rgba(0, 0, 0, 0.5);
        }
      }

      &:focus {
        outline: 0;
      }
    }

    input {
      background: url('./search.svg') no-repeat 25px 50%;
      background-size: 16px auto;
      padding-left: 50px;
      min-width: 125px;
      color: #fff;
      text-transform: none;
    }
  }
}

.before, .after {
  position: relative;
  z-index: 1;
}

.before {
  background: linear-gradient(90deg, black 30%, transparent) repeat-y 0 0;

  .decorativeGradient {
    background: linear-gradient(180deg,
      rgba(0,0,0,0.59) 0%,
      rgba(0,0,0,0.00) 26%,
      rgba(0,0,0,0.00) 76%, // originally 51%, changed for readability
      rgba(0,0,0,0.55) 93%, // originally 73%, changed for readability
      rgba(0,0,0,0.83) 99%
    ) repeat-x top left;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100vw + 33.3vw + 33.3vw;
    z-index: 1;
    pointer-events: none;
    display: none;

    @media (min-width: $mobileBreakpoint) {
      display: block;
    }
  }
}

.after {
  background: linear-gradient(-90deg, black 30%, transparent) repeat-y 0 0;
}

.decorativeGradient {
  background: linear-gradient(180deg,
    rgba(0,0,0,0.59) 0%,
    rgba(0,0,0,0.00) 26%,
    rgba(0,0,0,0.00) 51%,
    rgba(0,0,0,0.55) 73%,
    rgba(0,0,0,0.83) 99%
  ) repeat-x top left;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;


}

// holder grid item

.gridHolder {
  position: absolute;
  // overflow: hidden;
  height: 100%;
  perspective: 1000px;
  //margin: 0 -33.3vw;

  $width: 30vw;
  $perRow: 5;
  $gutter: 10px;
  overflow: hidden;

  width: calc(80vw * #{$perRow});
  margin-left: calc(#{(80vw * $perRow - 100vw) / -2} - #{$gutter} / 2);

  @media (min-width: $mobileBreakpoint) {
    width: calc(#{$width} * #{$perRow});
    margin-left: calc(#{($width * $perRow - 100vw) / -2} - #{$gutter} / 2);
  }

  .grid {
    width: 100%;
    position: absolute;
    transition: all 0.5s ease-in-out;
    transition-property: top, left;
  }

  .gridItemFrame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    padding: 0;
    margin: 0;
    border: 0;
  }

  .gridItem {
    background: no-repeat 50% 50%;
    background-size: cover;
    position: relative;
    float: left;
    //width: 25vw;
    //height: 15vw;
    //height: 20vw;
    width: calc(80vw - (#{$gutter} * 2));
    height: calc((80vw - (#{$gutter} * 2)) * 9 / 16);
    margin: $gutter;
    border-radius: 6px;
    opacity: 0.7;
    transition-duration: 0.3s;

    @media (min-width: $mobileBreakpoint) {
      width: calc(#{$width} - (#{$gutter} * 2));
      height: calc((#{$width} - (#{$gutter} * 2)) * 9 / 16);
    }

    &:before {
      content: '';
      background: url('./clickIcon.svg') no-repeat 0 0;
      background-size: 100% auto;
      width: 30px;
      height: 30px;
      position: absolute;
      // right: 50%;
      // margin-left: -20px / 2;
      right: 20px;
      bottom: 20px;
    }

    &.playing .gridItemInner:after {
      background-image: url('../Audio/stop.svg');
    }

    .gridItemTitleAndControl {
      background: linear-gradient(180deg, rgba(0, 0, 0, .85), transparent 30%) no-repeat 0 0;
      background-size: 100% 100px;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 5px 0 10px;
      z-index: 1;
      width: 100%;
      box-sizing: border-box;

      h3, a {
        position: static;
      }
      h3 {
        width: 100%;
        margin-bottom: 3px;
      }

      @media (min-width: $mobileBreakpoint) {
        padding: 10px 20px 0 20px;

        h3 {
          margin-bottom: 0.5em;
        }
      }
    }

    .gridItemInner {
      &:after {
        display: none; // Disabled for now

        content: '';
        background: url('../ShowreelPage/play.svg') no-repeat 50% 50%;
        width: 73px;
        height: 73px;
        margin-left: -1 * 72px / 2;
        margin-top: -1 * 72px / 2;
        left: 50%;
        top: 50%;
        position: absolute;
        z-index: 2;
      }
      width: 100%;
      height: 100%;
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.5s ease-in-out;
      position: relative;
    }

    &.activeGridItem {
      opacity: 1;

      &:before {
        display: none;
      }

      .gridItemInner {
        opacity: 1;
        cursor: default;
        pointer-events: auto;
      }
    }

    &:hover
    {
      cursor: pointer;
      opacity: 1;
    }

    img
    {
      position: absolute;
      top: 100px;
      left: 180px;
      background-color: rgba(0,0,0,0.4);
      border-radius: 50%;
      box-shadow: 0 0 40px rgba(0,0,0,0.4);
    }

    h3
    {
      width: calc(100% - 200px);
      position: absolute;
      left: 20px;
      bottom: 20px;
      font-weight: normal;
      text-shadow: 2px 2px 2px rgba(0,0,0,0.4);
      color: #fff;
      margin: 0;
    }
  }
}

.button {
  min-height: 2em;
  min-width: 150px;
  box-sizing: border-box;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 1.5;
  position: absolute;
  margin-bottom: 0;
  bottom: 20px;
  right: 20px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);

  border: 1px solid #EA5C1C;
  background: #EA5C1C;
  border-radius: 30px;
  color: #fff;

  &:link, &:visited, &:hover, &:active {
    color: #fff;
  }
  &:hover {
    background-color: lighten(#EA5C1C, 5%);
    border-color: lighten(#EA5C1C, 5%);
    color: #fff;
  }
}
