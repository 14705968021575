@import '../App/variables';

.outer {
  @include ABC(0, 100px);
}

.Home {
  width: 100%;
  height: 100%;
  display: flex;
  flex: {
    grow: 1;
    shrink: 0;
    direction: column;
  }
  // Push content as far down as possible.
  // justify-content: flex-end;
  padding: 0 2px 40px 30px;
  box-sizing: border-box; // allow padding on <Content>
  position: relative;
  z-index: 0;

  @media (min-width: $mobileBreakpoint) {
    padding: 0 2px 40px 80px;
  }
}

.LogoContainer {
  width: 115px;

  img {
    height: 102px;
    width: 115px;
  }
}

.before {
  display: none;
}

.after {
  width: 100px;
}

.language {
  display: none;
  @media (min-width: $mobileBreakpoint) {
    display: block;
  }
}
