.fullWidthBackground {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  background: no-repeat 50% 50%;
  background-size: cover;
  opacity: 0.5;
  filter: blur(10px);
  transition: opacity 0.5s ease-in-out 1s;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(transparent, #191818 80%) no-repeat 50% 50%;
    background-size: cover;
  }

  &.hasNoBackground {
    opacity: 0;
  }
}
