@import '../App/variables';

.tinyTrack {
  width: 60px;
  height: 90px;
  margin: 5px 10px 5px 5px;
  border: 0;
  padding: 0;

  :global {
    .carousel .slide & {
      width: 60px;
      height: 90px;
      margin: 5px 10px 5px 5px;
    }
  }
}

.carousel {
  //background-color: #0e0b0d;
  background-color: #080808;
  @import '../LightboxPage/LightboxPageVariables.scss';
  padding: 0;
  margin-left: -10px;
  margin-right: -10px;
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (min-width: $mobileBreakpoint) {
    margin-left: -1 * $lightboxPagePadding;
    margin-right: -1 * $lightboxPagePadding;
  }

  .slide {
    //background: no-repeat 200% 100%;
    background: no-repeat -300px 100%;
    background-size: auto 100%;
    flex: 1;
    display: flex;
    margin: 0 0;
    padding: 0 20px 20px 20px;
    position: relative;
    z-index: 1;

    canvas.slideBlurredBg {
      display: none;
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: 200% 100%; // same as parent
    }

    @media (min-height: 300px) and (max-width: $mobileBreakpoint) {
      // img is ~300px, with an aspect ratio of 9 : 6.75 = 1.33
      // it should be 300/1.33/2 = ~112 pixels offset.
      background-position-x: -112px;
    }
    @media (min-height: 400px) and (max-width: $mobileBreakpoint) {
      background-position-x: -150px;
    }
    @media (min-height: 500px) and (max-width: $mobileBreakpoint) {
      background-position-x: -188px;
    }
    @media (min-height: 600px) and (max-width: $mobileBreakpoint) {
      background-position-x: -225px;
    }
    @media (min-height: 700px) and (max-width: $mobileBreakpoint) {
      background-position-x: -263px;
    }
    @media (min-height: 800px) and (max-width: $mobileBreakpoint) {
      background-position-x: -300px;
    }
    @media (min-height: 900px) and (max-width: $mobileBreakpoint) {
      background-position-x: -338px;
    }
    @media (min-height: 1000px) and (max-width: $mobileBreakpoint) {
      background-position-x: -375px;
    }
    @media (min-height: 1100px) and (max-width: $mobileBreakpoint) {
      background-position-x: -413px;
    }

    /*@media (min-height: 900px) {
      // Mobile but tall
      background-position: -350px 100%;
    }
    @media (min-height: 1000px) {
      // Mobile but tall
      background-position: -475px 100%;
    }*/

    @media (min-width: $mobileBreakpoint) {
      // margin: 0 80px;
      // padding: 0 0 120px 0;
      margin: 0;
      padding: 0 80px 120px 80px;
      background: no-repeat bottom center;
      background-size: auto 100%;

      canvas.slideBlurredBg {
        left: 50%;
        transform: translateX(-50%);
        width: auto;
        display: block;
        object-position: bottom center;
        object-fit: cover;
      }
    }

    .innerWidthBreaker {
      width: 100%;
      margin: 0 0;
      transition: 0.5s opacity ease-in-out 0s;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: flex-start;
      align-items: flex-start;
      justify-content: space-between;
      opacity: 0;
      pointer-events: none;
      padding-left: 120px;

      @media (min-width: $mobileBreakpoint) {
        $width: 180%;
        width: $width;
        margin: 0 ($width - 100%) / -2;
        padding-left: 0;
        align-content: flex-end;
        align-items: flex-end;
      }
      /*
      height: auto;
background: red;
align-content: flex-end;
padding-left: 10px;
      */

      iframe {
        width: 1px;
        margin: 0;
      }
    }
  }
  // @todo Bg transparent if not active
  :global(.slide.selected) {
    z-index: 2;
    opacity: 1;
    transform: scale(1);
    filter: none;
    .slide .innerWidthBreaker {
      opacity: 1;
      pointer-events: auto;
    }
    .slideBlurredBg {
      display: none;
    }
  }

  .title {
    flex: 0 1 100%;
    margin-bottom: 20px;

    @media (min-width: $mobileBreakpoint) {
      margin-bottom: 75px;
    }
    @media (min-width: $largerDesktopBreakpoint) {
      width: 40%;
      flex: 0 0 40%;
    }
  }
  .text {
    width: 100%;
    flex: 0 0 100%;
    margin-bottom: 75px;

    @media (min-width: $mobileBreakpoint) {
      width: 35%;
      flex: 0 0 35%;
    }
    @media (min-width: $largerDesktopBreakpoint) {
      width: 35%;
      flex: 0 0 35%;
    }
  }

  @media (min-width: 1024px) {
    /*.title {
      flex-basis: 60%;
    }*/
    /*.text {
      flex-basis: 40%;
    }*/
  }

  h1 {
    font-size: 30px;
    line-height: 1.1;
    margin: 0 0 0.1em 0;
    opacity: 0.2;
    color: #fff;

    @media (min-width: $mobileBreakpoint) {
      font-size: 100px;
    }

  }
  h2 {
    font-size: 14px;
    font-family: $primaryFont;
    color: #8A8A8A;
    letter-spacing: -0.3px;
  }

  :global {
    .carousel-slider {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    .slider-wrapper {
      margin-top: 0;
      margin-bottom: 0;
      flex: 1;
      display: flex;
      // There is only one element here, but we need flex-direction here
      // explicitly because Firefox can't overflow otherwise due to a bug.
      // https://onfe.uk/blog/p/the-woes-of-firefox-s-flexbox/
      flex-direction: column;
    }
    .slider {
      display: flex;
      flex: 1;
      height: auto;
    }
    .slide {
      background: transparent;
      height: auto;
      flex: 1;
      display: flex;
      flex-direction: column;
      text-align: left;

      opacity: 0.5;
      transform-origin: 50% 100%;
      transform: scale(0.9);
      // filter: blur(2px); //@todo re-enable this
      transition: transform 1s ease-in-out 0s, filter 1s ease-in-out 0s;
    }

    // Put the dot indicators under the slideshow
    .carousel-status.carousel-status {
      position: absolute;
      display: block;
      font-size: 16px;
      line-height: 1.6;
      text-align: left;
      box-sizing: border-box;
    }

    .control-dots.control-dots {
      margin: 0;
      padding: 0;
      list-style-type: none;
      text-align: left;
      position: absolute;
      left: 150px;
      width: 75%;
      bottom: 20px;
      max-width: calc(100% - 150px);

      @media (min-width: $mobileBreakpoint) {
        bottom: 120px;
      }

      li {
        // margin: 0;
        // padding: 0;
      }
    }
  }

  .prev, .next {
    width: 40px;
    height: 40px;
    box-sizing: border-box;
    padding: 10px;
    display: inline-block;
    position: absolute;
    bottom: 50px;
    left: 140px;
    z-index: 2;
    cursor: pointer;
    pointer-events: auto;

    @media (min-width: $mobileBreakpoint) {
      bottom: 150px;
    }

    &.enabled {}
    &.disabled {
      opacity: 0.2;
    }
  }

  .next.next {
    left: 170px;
  }

  :global(.carousel-status) {
    top: auto;
    right: auto;
    left: 210px;
    bottom: 57px;
    color: #B6B6B6;
    margin: 0;
    padding: 0;
    line-height: 2;
    vertical-align: middle;

    @media (min-width: $mobileBreakpoint) {
      bottom: 157px;
    }
  }

  .status {
    color: #B6B6B6;
    font-family: $secondaryFont;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.6;
    letter-spacing: 0.1em;

    strong {
      font-weight: 900;
    }
  }
}
