@import '../App/variables';

.outer {
  @include ABC(0, 0);
}

.outerContent {
  display: flex;
  align-items: center;
}

.Library {
  padding: 0 85px 0;

  h1 {
    font-size: 54px;
    margin: 0 0;
  }

  .content {
    width: 60%;
    margin-top: 50px;

    @media (min-width: $mobileBreakpoint) {
      margin-top: 0;
    }
  }
}

.buttons {
  width: 315px;
  a {
    box-sizing: border-box;
    padding: 20px 10px;
    display: block;
    border: 1px solid #535353;
    text-align: center;
    color: #fff;
    text-decoration: none;
    margin-bottom: 20px;
    &.cta {
      border-color: $orange;
      color: $orange;
    }
  }
}

.tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(50% - 50px);
  align-items: flex-end;
  li {
    position: relative;
    list-style-type: none;
    margin-bottom: 5px;
    font-size: 14px;
    padding-left: 7px;
    &:before {
      content: " ";
      display: block;
      position: absolute;
      width: 12px;
      height: 9px;
      left: -16px;
      top: 50%;
      transform: translateY(-50%);
      background-image: url("./icons/checkmark.svg");
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}

.halfWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // margin-top: 50px;
}

.list {
  width: calc(100% / 3 * 2);
  border: 1px solid #212121;
  //border: 1px solid #fff;
  position: relative;
  box-sizing: border-box;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  ul {
    width: 100%;
  }
}

.audioPlayerTile.audioPlayerTile.audioPlayerTile {
  width: 100%;
  margin: 0;
}

.square.square {
  display: inline-block;
  position: relative;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.square,
.tile {
  width: calc(100% / 3 - 20px);
  background-position: bottom center;
  background-size: contain;
  background-repeat: no-repeat;
  max-width: 150px;
  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  &.first {
    margin-bottom: 30px;
  }
}

.image {
  width: calc(50% - 50px);
  display: flex;
  align-items: center;
  img {
    margin: 0 auto;
    height: 100%;
    width: auto;
    object-fit: contain;
    object-position: center;
    // mask-image: linear-gradient(to right, transparent 0%, black 75%);
  }
}

// @media only screen and (max-width: 1280px) {
  .list {
    width: 100%;
  }
  .square,
  .tile {
    width: calc(25% - 5px);
    margin-bottom: 30px;
  }
// }

@media only screen and (max-width: 1024px) {
  .tiles {
    width: 100%;
  }

  .image {
    display: none;
  }

  .Library {
    .content {
      width: 100%;
    }
  }

  .buttons {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    a {

      width: calc(50% - 20px);
    }
  }

  .halfWrapper {
    margin-top: 20px;
  }

  .list {
    padding: 20px 20px ;
  }
}

.after {
  .bg-large {
    background: url('./bg.jpg') no-repeat 50% 50%;
    background-size: cover;
    width: 100vw;
    height: 100%;
    margin-left: -100vw;
    position: absolute;
    z-index: -1;
    pointer-events: none;
  }
}

@media only screen and (max-width: 768px) {
  .Library {
    padding: 50px 20px;
  }

  .square.square,
  .tile.tile {
    width: calc(50% - 10px);
    // width: calc(25% - 20px);
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .buttons {
    a {
      width: calc(50% - 5px);
    }
  }
}

@media only screen and (max-width: 490px) {
  .buttons {
    a {
      width: 100%;
    }
  }
}
